import { SET_IS_FORM_SENDING, SET_IS_PRODUCT_INVOICE_LOADING, SET_PRODUCT_INVOICE } from './actions';

export const initialState = {
	productInvoice: null,
	isProductInvoiceLoading: false,
	isFormSending: false,
};

const productInvoiceModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_PRODUCT_INVOICE:
			return {
				...state,
				productInvoice: payload,
			};
		case SET_IS_PRODUCT_INVOICE_LOADING:
			return {
				...state,
				isProductInvoiceLoading: payload,
			};

		case SET_IS_FORM_SENDING:
			return {
				...state,
				isFormSending: payload,
			};
		default:
			return state;
	}
};

export default productInvoiceModalReducer;
