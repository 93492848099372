import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { StatusIndicator } from '../../../../components/status-indicator';
import { InfoIcon } from '../../../../components/info-icon';

const CarrierModalCustomCell = ({ rowData, columnId, onCustomCellButtonClick }) => {
	if (columnId === 'company') {
		return (
			<div className="carrier-modal-custom-cell__company">
				<div className="carrier-modal-custom-cell__contact-name">{rowData[columnId].name}</div>
				<div className="carrier-modal-custom-cell__company-code">{rowData[columnId].inn}</div>
			</div>
		);
	}

	if (columnId === 'price') {
		return (
			<div className="carrier-modal-custom-cell__price">
				{rowData.info && (
					<div className="carrier-modal-custom-cell__price-info">
						<InfoIcon popupContent={rowData.info} />
					</div>
				)}
				<span className="carrier-modal-custom-cell__price-label">{rowData.price}</span>
			</div>
		);
	}

	if (columnId === 'date') {
		return (
			<div className="carrier-modal-custom-cell__date">
				{rowData.status && (
					<div className="carrier-modal-custom-cell__date-status-indicator">
						<StatusIndicator status={rowData.status} />
					</div>
				)}
				<span className="carrier-modal-custom-cell__date-label">{rowData.date}</span>
			</div>
		);
	}

	if (columnId === 'action') {
		if (!rowData.isActionButtonVisible) {
			return null;
		}

		return (
			<div
				className="carrier-modal-custom-cell__button-wrapper"
				onClick={() => onCustomCellButtonClick(rowData.id)}
			>
				<div className="carrier-modal-custom-cell__button">Выбрать</div>
			</div>
		);
	}

	return rowData[columnId];
};

CarrierModalCustomCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
	onCustomCellButtonClick: PropTypes.func.isRequired,
};

export { CarrierModalCustomCell };
