import React from 'react';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormSelectField } from '../../../../../semantic-ui/components/form-select-field';
import { FormDoublePhoneField } from '../../../../../semantic-ui/components/form-double-phone-field';
import { normalizePhone } from '../../../../profile/utils';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { CustomDatePicker } from '../../../../../semantic-ui/components/custom-datepicker';

export const UserFormView = ({
	optionsList,
	isOptionsListLoading,
	onAddUserRoleButtonClick,
	isUserInfoLoading,
	isDriver,
	usersList,
	isUsersListLoading,
	isDisabled,
	onCheckboxChange,
}) => {
	return (
		<Grid className="user-form">
			<Grid.Row className="user-form__row">
				<Grid.Column width={6} className="user-form__column">
					Роль пользователя
				</Grid.Column>
				<Grid.Column width={7} className="user-form__column">
					<div className="user-form__column-with-button">
						<div className="user-form__field-with-button">
							<Field
								name="role"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								options={optionsList.usersRoles}
								search
								loading={isOptionsListLoading.usersRoles}
								noResultsMessage="Не найдено"
								placeholder="Роль"
								isWithoutSelectOnBlur
							/>
						</div>
						<div className="user-form__add-button" onClick={onAddUserRoleButtonClick} />
					</div>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="user-form__row">
				<Grid.Column width={6} className="user-form__column">
					Должность
				</Grid.Column>
				<Grid.Column width={7} className="user-form__column">
					<Field
						name="post"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите вашу должность"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="user-form__row">
				<Grid.Column width={6} className="user-form__column">
					Фамилия
				</Grid.Column>
				<Grid.Column width={7} className="user-form__column">
					<Field
						name="last_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Фамилия"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="user-form__row">
				<Grid.Column width={6} className="user-form__column">
					Имя
				</Grid.Column>
				<Grid.Column width={7} className="user-form__column">
					<Field
						name="first_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Имя"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="user-form__row">
				<Grid.Column width={6} className="user-form__column">
					Отчество
				</Grid.Column>
				<Grid.Column width={7} className="user-form__column">
					<Field
						name="middle_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Отчество"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			{isDriver && (
				<>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Серия паспорта
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="passport_series"
								type="text"
								component={FormInputField}
								autoComplete="off"
								placeholder="Серия паспорта"
								loading={isUserInfoLoading}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Номер паспорта
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="passport_number"
								type="text"
								component={FormInputField}
								autoComplete="off"
								placeholder="Номер паспорта"
								loading={isUserInfoLoading}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Дата выдачи паспорта
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="passport_date_issue"
								type="text"
								component={CustomDatePicker}
								autoComplete="off"
								placeholder="   .. /.. / ....  "
								dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
								loading={isUserInfoLoading}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Кем выдан паспорт
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="passport_issuing_authonity"
								type="text"
								component={FormInputField}
								autoComplete="off"
								placeholder="Кем выдан паспорт"
								loading={isUserInfoLoading}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Номер водительского удостоверения
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="driver_license_number"
								type="text"
								component={FormInputField}
								autoComplete="off"
								placeholder="Номер водительского удостоверения"
								loading={isUserInfoLoading}
							/>
						</Grid.Column>
					</Grid.Row>
				</>
			)}
			<Grid.Row className="user-form__row">
				<Grid.Column width={6} className="user-form__column">
					Почта
				</Grid.Column>
				<Grid.Column width={7} className="user-form__column">
					<Field
						name="email"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите адрес электронной почты"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="user-form__row">
				<Grid.Column width={6} className="user-form__column">
					Телефон
				</Grid.Column>
				<Grid.Column width={7} className="user-form__column">
					<FormDoublePhoneField
						mainFieldName="phone"
						additionalFieldName="extension"
						mainNormalize={normalizePhone}
						additionalPlaceholder="Добавочный"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="user-form__row">
				<Grid.Column width={6} className="user-form__column">
					Telegram ID
				</Grid.Column>
				<Grid.Column width={7} className="user-form__column">
					<Field
						name="telegram_id"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите Telegram ID"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			{!isDriver && (
				<>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Пароль
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="password"
								type="password"
								component={FormInputField}
								autoComplete="off"
								placeholder="Придумайте пароль"
								loading={isUserInfoLoading}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Подтверждение пароля
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="password_confirmation"
								type="password"
								component={FormInputField}
								autoComplete="off"
								placeholder="Введите пароль повторно"
								loading={isUserInfoLoading}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Переадресация уведомлений
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="redirect_ids"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								noResultsMessage="Не найдено"
								placeholder="Переадресация уведомлений"
								options={usersList}
								multiple
								search
								loading={isUsersListLoading}
								isWithoutSelectOnBlur
								dropdownClassName="static"
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Получать уведомления
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="is_notify"
								component={FormCheckboxField}
								toggle
								type="checkbox"
								onChange={() => onCheckboxChange('is_notify')}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Получать уведомления To Do List
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="is_notify_todo"
								component={FormCheckboxField}
								toggle
								type="checkbox"
								disabled={isDisabled.isNotifyTodo}
								onChange={() => onCheckboxChange('is_notify_todo')}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="user-form__row">
						<Grid.Column width={6} className="user-form__column">
							Получать уведомления To Do List по компании
						</Grid.Column>
						<Grid.Column width={7} className="user-form__column">
							<Field
								name="is_notify_todo_company"
								component={FormCheckboxField}
								toggle
								type="checkbox"
								disabled={isDisabled.isNotifyTodoCompany}
							/>
						</Grid.Column>
					</Grid.Row>
				</>
			)}
		</Grid>
	);
};

UserFormView.propTypes = {
	optionsList: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				key: PropTypes.string.isRequired,
			}),
		),
	),
	isOptionsListLoading: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
	onAddUserRoleButtonClick: PropTypes.func.isRequired,
	isUserInfoLoading: PropTypes.bool.isRequired,
	isDriver: PropTypes.bool.isRequired,
	usersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
		}),
	).isRequired,
	isUsersListLoading: PropTypes.bool.isRequired,
	isDisabled: PropTypes.objectOf(PropTypes.bool).isRequired,
	onCheckboxChange: PropTypes.func.isRequired,
};
