import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Link } from 'react-router-dom';

export const Header = ({ links, rightSideContent, searchInput, onAlertModalOpen }) => {
	return (
		<header className="header">
			<div className="header__content">
				<div className="header__side header__side_left">
					{searchInput}
					<nav className="header__nav">
						<ul className="header__link-list">
							{Object.entries(links).map(([key, value]) => (
								// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
								<li className="header__link-item" key={key} onClick={onAlertModalOpen}>
									<Link className="header__link" to={value}>
										{key}
									</Link>
								</li>
							))}
						</ul>
					</nav>
				</div>
				<div className="header__side header__side_right">{rightSideContent}</div>
			</div>
		</header>
	);
};

Header.propTypes = {
	rightSideContent: PropTypes.node,
	chatsList: PropTypes.node,
	links: PropTypes.objectOf(PropTypes.string).isRequired,
	searchInput: PropTypes.node,
	onAlertModalOpen: PropTypes.func.isRequired,
};
