/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/void-dom-elements-no-children */
import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { Status } from '../../../../../components/status';
import StatusButton from '../../../../../components/status-button';
import { BUTTONS_NAMES, BUTTONS_STYLES } from '../../../constants';

export const ReqStatusActionsView = ({ status, actions, onActionButtonClick, reqId, query, badges }) => {
	return (
		<div className="req-status-actions">
			<div className="req-status-actions__statuses">
				<div className="req-status-actions__title">Статус</div>
				{status && status.link && (
					<Link className="req-status-actions__link" to={`${status.link}${query ? `&${query}` : ''}`}>
						<Status status={status} isFullwidth />
					</Link>
				)}
				{status && !status.link && <Status status={status} isFullwidth />}
			</div>

			{actions && !isEmpty(actions) && (
				<div className="req-status-actions__actions">
					{Object.entries(actions).map(([key, value]) => {
						if (!value) {
							return null;
						}

						return (
							<div className="req-status-actions__button" key={key}>
								<StatusButton
									button={{ name: BUTTONS_NAMES[key], color: BUTTONS_STYLES[key] }}
									onClick={() => onActionButtonClick(key, reqId)}
								/>
								{badges[key]?.count && (
									<span
										className={`req-status-actions__button-badges req-status-actions__button-badges_${badges[key].color}`}
									>
										{badges[key].count}
									</span>
								)}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

ReqStatusActionsView.propTypes = {
	status: PropTypes.shape({
		name: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		description: PropTypes.string,
		link: PropTypes.string,
	}),
	actions: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.bool), PropTypes.array]),
	onActionButtonClick: PropTypes.func.isRequired,
	reqId: PropTypes.number.isRequired,
	query: PropTypes.string.isRequired,
	badges: PropTypes.objectOf(
		PropTypes.shape({
			count: PropTypes.number.isRequired,
			color: PropTypes.string.isRequired,
		}),
	),
};
