/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { initialState } from './reducer';
import { APPROVAL_TABLE_ROWS_NAMES, DATE_FORMAT, DATE_TIME_FORMAT } from './constants';
import { getCargoTime } from './utils';
import { BUTTONS_NAMES, BUTTONS_STYLES } from '../reqs-list/constants';

export const selectReqsDetailsModalDomain = state => state.reqsDetailsModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getReqDetailsSelector = () =>
	createSelector(selectReqsDetailsModalDomain, ({ reqDetails }) => {
		let data = {};

		if (reqDetails) {
			const {
				company,
				points,
				type,
				mode,
				weight,
				place,
				cost,
				comment,
				is_prr,
				description,
				user,
				volume,
				tk,
				driver,
				car,
				trailer,
				fetch_at,
				fetch_time_from,
				fetch_time_to,
				offer,
				bet,
				features,
				created_at,
				rate,
				flight,
				size,
				is_validated,
				is_thermo_check,
				is_unchanged_fetch_at,
				product_invoice,
				actions,
			} = reqDetails;

			const addressFrom = points.find(({ type }) => type === 'from') || {};

			const cargoTimeFrom = getCargoTime(addressFrom.time_from, addressFrom.time_to);

			const pointsTo = points.reduce((acc, point, index) => {
				if (point.type === 'to') {
					acc.push({ group: `Грузополучатель №${index}` });
					acc.push({
						id: `${index}address_to`,
						title: APPROVAL_TABLE_ROWS_NAMES.address_to,
						value: {
							name: point.partner?.name || '-',
							inn: point.partner?.inn,
							address: point.address?.address || '-',
							time: point.address?.work_mode || '-',
							status_info: point.date_info,
							cargo_date: moment(point.date).format(DATE_FORMAT),
						},
						sub_value: {
							name: `${point?.contact?.last_name || ''} ${point?.contact?.first_name || ''} ${
								point?.contact?.middle_name || ''
							}`,
							code: point?.contact?.phone || '',
						},
					});

					if (point.done_at !== null) {
						acc.push({
							id: `${index}doneAt`,
							title: APPROVAL_TABLE_ROWS_NAMES.done_at,
							value: {
								doneAt: moment(point.done_at).format(DATE_FORMAT),
								doneInfo: point.done_info,
							},
						});
					}

					if (point.weight !== null || point.place !== null || point.volume !== null) {
						acc.push({
							id: `${index}cargo`,
							title: APPROVAL_TABLE_ROWS_NAMES.cargo,
							value: {
								weight: point.weight,
								volume: point.volume,
								place: point.place,
							},
						});
					}

					if (point.size !== null) {
						const pointSizes = point.size?.reduce((acc, item, index) => {
							acc.push({
								id: `size${index}`,
								title: `Габаритные характеристики места № ${index + 1}`,
								value: item,
							});

							return acc;
						}, []);

						acc.push(...pointSizes);
					}

					if (point.is_prr !== undefined) {
						acc.push({
							id: `${index}is_prr`,
							title: APPROVAL_TABLE_ROWS_NAMES.is_prr,
							value: point.is_prr ? 'Да' : 'Нет',
						});
					}

					if (point.description) {
						acc.push({
							id: `${index}description`,
							title: APPROVAL_TABLE_ROWS_NAMES.description,
							value: point.description,
						});
					}
				}

				return acc;
			}, []);

			const isTkGroupVisible = Boolean(tk || driver || car || trailer || fetch_at || offer?.sum || offer?.user);

			const isCargoGroupVisible = Boolean(weight !== null || place !== null || volume !== null);

			const sizes = size?.reduce((acc, item, index) => {
				acc.push({
					id: `size_${index}`,
					title: `Габаритные характеристики места № ${index + 1}`,
					value: item,
				});

				return acc;
			}, []);

			data = {
				customer: {
					name: company?.name || '-',
					code: company?.inn || '-',
					sub_value: {
						name: `${company?.contact?.last_name || ''} ${company?.contact?.first_name || ''} ${
							company?.contact?.middle_name || ''
						}`,
						code: company?.contact?.phone || '',
					},
				},
				customer_group: { group: 'Грузоотправитель' },
				address_from: {
					name: addressFrom.partner?.name || '-',
					inn: addressFrom.partner?.inn,
					address: addressFrom.address?.address || '-',
					time: addressFrom.address?.work_mode || '-',
					status_info: addressFrom.date_info,
					cargo_date: `${moment(addressFrom.date).format(DATE_FORMAT)} ${cargoTimeFrom}`,
					sub_value: {
						name: `${addressFrom?.contact?.last_name || ''} ${addressFrom?.contact?.first_name || ''} ${
							addressFrom?.contact?.middle_name || ''
						}`,
						code: addressFrom?.contact?.phone || '',
					},
					is_unchanged_fetch_at,
				},
				...(product_invoice
					? {
							product_invoice: {
								product_invoice,
								...(actions?.setProductInvoice && { action: actions.setProductInvoice }),
							},
					  }
					: {}),
				...(rate ? { rate: rate.name } : {}),
				type: type?.name || '---',
				mode: mode?.name ? `${mode.name}°C` : 'Отсутствует',
				...(isCargoGroupVisible ? { cargo: { weight, volume, place } } : {}),
				...(size ? { size: sizes } : {}),
				cost: cost ? `${cost.toFixed(2)} руб.` : '---',
				...(comment ? { comment } : {}),
				is_validated: is_validated ? 'Да' : 'Нет',
				is_thermo_check: is_thermo_check ? 'Да' : 'Нет',
				features: features?.length < 1 ? 'Нет' : features,
				is_prr: is_prr ? 'Да' : 'Нет',
				...(description ? { description } : {}),
				created_at: created_at ? moment(created_at).format(DATE_FORMAT) : '---',
				...(user
					? {
							author: {
								title: `${user?.last_name || ''} ${user?.first_name || ''}`,
								text: user?.role?.name || '',
							},
					  }
					: {}),
				...(isTkGroupVisible ? { tk_group: { group: 'Транспортная компания' } } : {}),
				...(tk
					? {
							tk: {
								title: tk?.name || '',
								text: tk?.inn || '',
								sub_value: {
									name: `${tk?.contact?.last_name || ''} ${tk?.contact?.first_name || ''} ${
										tk?.contact?.middle_name || ''
									}`,
									code: tk?.contact?.phone || '',
								},
							},
					  }
					: {}),
				...(driver
					? {
							driver: {
								title: `${driver?.last_name || ''} ${driver?.first_name || ''} ${
									driver?.middle_name || ''
								}`,
								text: driver?.phone || '',
								sub_value: {
									passport: driver?.passport || '',
									driver_license: driver?.driver_license || '',
								},
							},
					  }
					: {}),
				...(car
					? {
							car: { title: car?.name || '', text: car?.number || '' },
					  }
					: {}),
				...(trailer
					? {
							trailer: { title: trailer?.name || '', text: trailer?.number || '' },
					  }
					: {}),
				...(fetch_at
					? {
							fetch_at: `${moment(fetch_at).format(DATE_FORMAT)} ${
								fetch_time_from ? `${fetch_time_from} - ${fetch_time_to}` : ''
							}`,
					  }
					: {}),
				...(flight ? { flight: { flight, ...(actions?.setFlight && { action: actions.setFlight }) } } : {}),
				...(offer?.sum
					? {
							sum: {
								sum: `${offer?.sum.toFixed(2)} руб.`,
								...(offer?.info && { info: offer.info }),
								...(offer?.link
									? {
											sub_value: {
												text: offer?.link?.text || '',
												url: offer?.link?.url || '',
											},
									  }
									: {}),
							},
					  }
					: {}),
				...(offer?.user && offer?.user?.last_name
					? {
							offer_author: {
								title: `${offer?.user?.last_name || ''} ${offer?.user?.first_name || ''} ${
									offer?.user?.middle_name || ''
								}`,
								text: offer?.created_at ? moment(offer.created_at).format(DATE_FORMAT) : '',
							},
					  }
					: {}),
				points: {
					id: 'points',
					points: pointsTo,
				},
				...(bet
					? {
							bid: {
								count: bet?.sum ? `${bet?.sum.toFixed(2)} руб.` : '---',
								date: bet?.date ? moment(bet.date).format(DATE_FORMAT) : '---',
								sub_value: {
									name: `${bet?.user?.last_name || ''} ${bet?.user?.first_name || ''} ${
										bet?.user?.middle_name || ''
									}`,
									date: bet?.created_at ? moment(bet.created_at).format(DATE_TIME_FORMAT) : '',
								},
							},
					  }
					: {}),
			};
		}

		return Object.entries(data).reduce((result, [key, value]) => {
			if (value.group) {
				result.push(value);
			} else if (value.id === 'points') {
				value.points.forEach(point => {
					result.push(point);
				});
			} else if (key === 'size') {
				result.push(...value);
			} else {
				result.push({
					id: key,
					title: APPROVAL_TABLE_ROWS_NAMES[key],
					value,
					sub_value: value.sub_value ? value.sub_value : '',
				});
			}

			return result;
		}, []);
	});

export const getIsReqDetailsLoadingSelector = () =>
	createSelector(selectReqsDetailsModalDomain, ({ isReqDetailsLoading }) => isReqDetailsLoading);

export const getLastRowStatusSelector = () =>
	createSelector(selectReqsDetailsModalDomain, ({ reqDetails }) => {
		return reqDetails?.bet?.status && reqDetails?.bet ? reqDetails.bet.status : '';
	});

export const getIsDocsTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'reqs_details' && query?.tab === 'docs');
	});

export const getIsChatTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'reqs_details' && query?.tab === 'chat');
	});

export const getReqNameSelector = () =>
	createSelector(
		selectReqsDetailsModalDomain,
		getIsDocsTabShownSelector(),
		getIsChatTabShownSelector(),
		({ reqDetails }) => {
			return `Заявка #${reqDetails?.id || '-'} от ${
				reqDetails?.created_at ? moment(reqDetails.created_at).format(DATE_TIME_FORMAT) : '-'
			}`;
		},
	);

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'reqs_details' && query?.tab === 'audit');
	});

export const getIsEventTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'reqs_details' && query?.tab === 'event');
	});

export const getModalStatusesSelector = () =>
	createSelector(
		selectReqsDetailsModalDomain,
		getIsAuditTabShownSelector(),
		getIsDocsTabShownSelector(),
		getIsChatTabShownSelector(),
		({ reqDetails }, isAuditTabShown, isDocsTabShown, isChatTabShown) => {
			const collectStatuses = [];

			if (!isAuditTabShown && !isDocsTabShown && !isChatTabShown) {
				if (reqDetails?.tab && !isEmpty(reqDetails?.tab)) {
					collectStatuses.push(reqDetails?.tab);
				}
				if (reqDetails?.status && !isEmpty(reqDetails?.status)) {
					collectStatuses.push(reqDetails?.status);
				}
			}

			return collectStatuses;
		},
	);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'reqs_details');
	});

export const getActiveReqIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return query?.modal === 'reqs_details' ? query?.id : null;
	});

export const getModalActionsSelector = () =>
	createSelector(selectReqsDetailsModalDomain, ({ reqDetails }) => {
		return (
			Object.entries(reqDetails?.actions || {}).reduce(
				(result, [key, value]) => {
					if (
						!value ||
						[
							'bet',
							'unbet',
							'setProductInvoice',
							'setFlight',
							'setCompanyNote',
							'setChosenCompany',
						].includes(key)
					) {
						return result;
					}

					if (BUTTONS_STYLES[key] === 'success') {
						result.right.push({ color: BUTTONS_STYLES[key], text: BUTTONS_NAMES[key], key });
					} else {
						result.left.push({ color: BUTTONS_STYLES[key], text: BUTTONS_NAMES[key], key });
					}

					return result;
				},
				{ left: [], right: [] },
			) || { left: [], right: [] }
		);
	});

export const getReqDocsSelector = () =>
	createSelector(selectReqsDetailsModalDomain, ({ reqDocs }) => {
		if (!reqDocs) {
			return [];
		}

		return reqDocs;
	});

export const getIsReqDocsLoadingSelector = () =>
	createSelector(selectReqsDetailsModalDomain, ({ isReqDocsLoading }) => isReqDocsLoading);

export const getReqTabsSelector = () =>
	createSelector(selectReqsDetailsModalDomain, ({ reqDetails }) => reqDetails?.tabs || {});

export const getEventDataSelector = () => createSelector(selectReqsDetailsModalDomain, ({ eventData }) => eventData);

export const getIsEventDataLoadingSelector = () =>
	createSelector(selectReqsDetailsModalDomain, ({ isEventDataLoading }) => isEventDataLoading);
