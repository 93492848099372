export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((prev, [key, error]) => {
		if (!prev[key]) {
			prev[key] = {};
		}

		prev[key] = error;

		return prev;
	}, {});
};
