/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectProductInvoiceModalDomain = state => state.productInvoiceModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsProductInvoiceLoadingSelector = () =>
	createSelector(selectProductInvoiceModalDomain, ({ isProductInvoiceLoading }) => isProductInvoiceLoading);

export const getIsFormSendingSelector = () =>
	createSelector(selectProductInvoiceModalDomain, ({ isFormSending }) => isFormSending);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'product_invoice');
	});

export const getDataForRequestSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getFormInitialValuesSelector = () =>
	createSelector(
		selectProductInvoiceModalDomain,
		getFormValues(FORM_NAME),
		({ productInvoice, isProductInvoiceLoading }, formValues) => {
			if (isProductInvoiceLoading) {
				return {};
			}

			return {
				product_invoice: formValues?.invoice || productInvoice?.product_invoice || '',
			};
		},
	);
