export const GET_PRODUCT_INVOICE = 'product-invoice/GET_PRODUCT_INVOICE';
export const getProductInvoiceAction = payload => ({
	type: GET_PRODUCT_INVOICE,
	payload,
});

export const SET_PRODUCT_INVOICE = 'product-invoice/SET_PRODUCT_INVOICE';
export const setProductProductInvoiceAction = payload => ({
	type: SET_PRODUCT_INVOICE,
	payload,
});

export const SET_IS_PRODUCT_INVOICE_LOADING = 'product-invoice/SET_IS_PRODUCT_INVOICE_LOADING';
export const setIsProductProductInvoiceLoadingAction = payload => ({
	type: SET_IS_PRODUCT_INVOICE_LOADING,
	payload,
});

export const SEND_INVOICE = 'product-invoice/SEND_INVOICE';
export const sendInvoiceAction = payload => ({
	type: SEND_INVOICE,
	payload,
});

export const SET_IS_FORM_SENDING = 'product-invoice/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});
