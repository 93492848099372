import { CustomModalCell } from '../../components/custom-modal-cell';

export const FORM_NAME = 'user-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TABLE_COLUMNS_NAMES = [{ id: 'title' }, { id: 'value', customComponent: CustomModalCell }];

export const TABLE_ROWS_NAMES = {
	company: 'Компания',
	role: 'Роль',
	post: 'Должность',
	email: 'Почта',
	phone: 'Телефон',
	telegramId: 'Telegram ID',
	isNotify: 'Уведомления',
	author: 'Автор',
};

export const DRIVER_TABLE_ROWS_NAMES = {
	company: 'Компания',
	role: 'Роль',
	post: 'Должность',
	passport_series: 'Серия паспорта',
	passport_number: 'Номер паспорта',
	passport_date_issue: 'Дата выдачи паспорта',
	passport_issuing_authonity: 'Кем выдан паспорт',
	driver_license_number: 'Номер водительского удостоверения',
	email: 'Почта',
	phone: 'Телефон',
	telegramId: 'Telegram ID',
	isNotify: 'Уведомления',
	author: 'Автор',
};

export const FIELDS_FOR_VALIDATE = [
	'role',
	'post',
	'last_name',
	'first_name',
	'email',
	'phone',
	'telegram_id',
	'company',
	'redirect_ids',
];

export const REQUIRED_FIELDS_FOR_VALIDATE = ['role', 'post', 'last_name', 'first_name', 'email', 'phone', 'company'];

export const DRIVER_FIELDS_FOR_VALIDATE = [
	'role',
	'post',
	'last_name',
	'first_name',
	'passport_number',
	'passport_date_issue',
	'passport_issuing_authonity',
	'driver_license_number',
	'email',
	'phone',
	'telegram_id',
	'company',
	'redirect_ids',
];

export const REQUIRED_DRIVER_FIELDS_FOR_VALIDATE = [
	'role',
	'post',
	'last_name',
	'first_name',
	'passport_number',
	'passport_date_issue',
	'passport_issuing_authonity',
	'driver_license_number',
	'phone',
	'company',
];

export const DRIVER_ROLE_NUMBER = 2;

export const DATE_FORMAT = 'DD.MM.YYYY';
