import { CarrierModalCustomCell } from './components/carrier-modal-custom-cell';

export const TABLE_COLUMNS_NAMES = [
	{ id: 'company', name: 'Компания', width: '335px', customComponent: CarrierModalCustomCell },
	{ id: 'price', name: 'Цена', width: '215px', customComponent: CarrierModalCustomCell },
	{ id: 'date', name: 'Дата доставки', width: '168px', customComponent: CarrierModalCustomCell },
	{ id: 'action', name: '', customComponent: CarrierModalCustomCell },
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const DATE_FORMAT = 'DD.MM.YYYY';

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';
