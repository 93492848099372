import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { ProductInvoiceFormView } from './product-invoice-form-view';
import { FORM_NAME } from '../../constants';
// import { validate } from '../../utils';
import { getFormInitialValuesSelector, getIsProductInvoiceLoadingSelector } from '../../selectors';

const ProductInvoiceFormWrapper = ({ isProductInvoiceLoading }) => {
	return <ProductInvoiceFormView isLoading={isProductInvoiceLoading} />;
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	// onSubmit: validate,
})(ProductInvoiceFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	isProductInvoiceLoading: getIsProductInvoiceLoadingSelector(),
});

const ProductInvoiceForm = connect(mapStateToProps)(withForm);

ProductInvoiceFormWrapper.propTypes = {
	isProductInvoiceLoading: PropTypes.bool.isRequired,
};

export { ProductInvoiceForm };
