import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Loader } from 'semantic-ui-react';
import { Field } from 'redux-form';
import './styles.less';
import { FormTextAreaField } from '../../../../../semantic-ui/components/form-textarea-field';

export const SpecifyFlightFormView = ({ isLoading }) => {
	return (
		<>
			<div className="specify-flight-form">
				{isLoading ? (
					<div className="specify-flight-form__loader">
						<Loader active />
					</div>
				) : (
					<Grid className="specify-flight-form__content">
						<Grid.Row className="specify-flight-form__content-row">
							<Grid.Column width={15} className="specify-flight-form__content-column">
								Рейс
								<div className="specify-flight-form__content-column-field">
									<Field
										name="flight"
										type="text"
										component={FormTextAreaField}
										autoComplete="off"
										width="100%"
										height="100%"
										resize="vertical"
										rows={6}
										placeholder="Укажите, пожалуйста, информацию ..."
									/>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				)}
			</div>
		</>
	);
};

SpecifyFlightFormView.propTypes = {
	isLoading: PropTypes.bool.isRequired,
};
